import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
    collection,
    addDoc,
    serverTimestamp,
    updateDoc,
    setDoc,
    doc,
} from "firebase/firestore";
import {
    ref,
    uploadBytes,
    getDownloadURL,
    deleteObject,
} from "firebase/storage";
import { auth, database, storage } from "../firebaseConfig";
import "./CreateEventPage.css";
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from "react-places-autocomplete";
import { onAuthStateChanged } from "firebase/auth";
import imageCompression from "browser-image-compression";

const CreateEventPage = () => {
    const [eventTitle, setEventTitle] = useState("");
    const [eventCategory, setEventCategory] = useState("");
    const [eventDate, setEventDate] = useState(new Date());
    const [eventEndDate, setEventEndDate] = useState(new Date());
    const [selectedCity, setSelectedCity] = useState("");
    const [isPublicEvent, setIsPublicEvent] = useState(false);
    const [eventDescription, setEventDescription] = useState("");
    const [eventPhoto, setEventPhoto] = useState(null);
    const [previewUrl, setPreviewUrl] = useState(null);
    const [eventLink, setEventLink] = useState("");
    const [eventAddress, setEventAddress] = useState("");
    const navigate = useNavigate();

    const location = useLocation();
    const eventData = location.state?.eventData;
    const eventId = location.state?.eventData?.id;

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (!user) {
                // User is not authenticated, redirect to the login page
                navigate("/login");
            }
        });

        return () => {
            unsubscribe();
        };
    }, [navigate]);

    const handleCityChange = (event) => {
        setSelectedCity(event.target.value);
    };

    const handlePublicToggle = () => {
        setIsPublicEvent(!isPublicEvent);
    };

    const handlePhotoChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
            try {
                const compressedFile = await imageCompression(file, {
                    maxSizeMB: 1, // Adjust the maximum size as needed (e.g., 1MB)
                    maxWidthOrHeight: 1920, // Adjust the maximum width or height as needed
                });
                setEventPhoto(compressedFile);

                const reader = new FileReader();
                reader.onloadend = () => {
                    setPreviewUrl(reader.result);
                };
                reader.readAsDataURL(compressedFile);
            } catch (error) {
                console.error("Error compressing image:", error);
            }
        } else {
            setPreviewUrl(null);
        }
    };

    const handlePhotoDelete = () => {
        setEventPhoto(null);
        setPreviewUrl(null);
    };

    useEffect(() => {
        if (eventData) {
            setEventTitle(eventData.eventTitle || "");
            setEventCategory(eventData.eventCategory || "");
            setEventDate(
                eventData.eventDate
                    ? new Date(eventData.eventDate.seconds * 1000)
                    : new Date()
            );
            setEventEndDate(
                eventData.eventEndDate
                    ? new Date(eventData.eventEndDate.seconds * 1000)
                    : new Date()
            );
            setSelectedCity(eventData.eventLocation || "");
            setIsPublicEvent(eventData.eventLocation ? true : false);
            setEventDescription(eventData.eventDescription || "");
            setEventLink(eventData.eventLink || "");
            setEventAddress(eventData.eventAddress || "");

            // Set the previewUrl with the existing eventImage URL
            if (eventData.eventImage) {
                setPreviewUrl(eventData.eventImage);
            }
        }
    }, [eventData]);

    const handleAddressSelect = async (address) => {
        try {
            const results = await geocodeByAddress(address);
            const latLng = await getLatLng(results[0]);
            setEventAddress(address);
            // You can store the latitude and longitude in separate state variables if needed
            // setEventLatitude(latLng.lat);
            // setEventLongitude(latLng.lng);
        } catch (error) {
            console.error("Error", error);
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleEventDateChange = (date) => {
        setEventDate(date);
        setEventEndDate(date); // Update eventEndDate to the same date as eventDate
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true); // Set isLoading to true before starting the upload

        try {
            const currentUser = auth.currentUser;
            if (!currentUser) {
                console.log("User not authenticated");
                return;
            }

            if (
                !eventTitle ||
                !eventCategory ||
                (isPublicEvent && !selectedCity)
            ) {
                console.log("Required fields are missing");
                return;
            }

            // Validate that eventEndDate is not before eventDate
            if (eventEndDate < eventDate) {
                console.log("End date cannot be before the start date");
                // You can display an error message to the user here
                return;
            }

            const updatedEventData = {
                eventTitle,
                eventCategory,
                eventDate: eventDate,
                eventEndDate: eventEndDate,
                eventLocation: isPublicEvent ? selectedCity : "",
                eventDescription,
                eventLink,
                eventAddress,
            };

            if (eventPhoto) {
                const storageRef = ref(
                    storage,
                    `uploaded_images/${eventPhoto.name}`
                );
                await uploadBytes(storageRef, eventPhoto);
                const downloadURL = await getDownloadURL(storageRef);
                updatedEventData.eventImage = downloadURL;
            } else if (eventData && eventData.eventImage && !previewUrl) {
                // Photo was removed during editing
                const storageRef = ref(storage, eventData.eventImage);
                await deleteObject(storageRef);
                updatedEventData.eventImage = null;
            } else if (!eventPhoto && !previewUrl) {
                // No photo selected and no existing photo, set eventImage to null
                updatedEventData.eventImage = null;
            }

            let eventDocId;

            if (eventId) {
                // Update existing event document
                const eventRef = doc(database, "events", eventId);
                await updateDoc(eventRef, updatedEventData);
                console.log("Event updated successfully");
                eventDocId = eventId;
            } else {
                // Create new event document
                updatedEventData.uid = currentUser.uid;
                updatedEventData.attending = [currentUser.uid];
                const eventRef = await addDoc(
                    collection(database, "events"),
                    updatedEventData
                );
                console.log("New event created successfully");
                eventDocId = eventRef.id;
            }

            // Generate a unique identifier or timestamp
            const confettiToken = Date.now().toString();

            // Navigate to the EventLandingPage with the eventId
            navigate(`/event/${eventDocId}?confettiToken=${confettiToken}`);
        } catch (error) {
            console.error("Error saving event:", error);
        }
        setIsLoading(false); // Set isLoading to false after the upload is complete
    };

    return (
        <div className="create-event-page-ce">
            <form onSubmit={handleSubmit} className="event-form-ce">
                <div className="event-title-container-ce">
                    <h3 className="event-title-label-ce">
                        what's the plan? <span className="emoji">💫</span>
                    </h3>
                    <input
                        type="text"
                        value={eventTitle}
                        onChange={(e) => setEventTitle(e.target.value)}
                        className="event-title-input-ce"
                        required
                        autoFocus
                        placeholder="title"
                    />
                </div>
                <div className="event-category-container-ce">
                    <select
                        id="eventCategory"
                        value={eventCategory}
                        onChange={(e) => setEventCategory(e.target.value)}
                        className="event-category-select-ce"
                        required
                    >
                        <option value="">
                            <span className="emoji">🤩</span> select a category
                        </option>
                        <option value="🎨 Art">
                            <span className="emoji">🎨</span> Art
                        </option>
                        <option value="🌲 Outdoors">
                            <span className="emoji">🌲</span> Outdoors
                        </option>
                        <option value="🏅 Sports">
                            <span className="emoji">🏅</span> Sports
                        </option>
                        <option value="🍽️ Dining">
                            <span className="emoji">🍽️</span> Dining
                        </option>
                        <option value="🎮 Leisure">
                            <span className="emoji">🎮</span> Leisure
                        </option>
                        <option value="🔨 DIY">
                            <span className="emoji">🔨</span> DIY
                        </option>
                        <option value="🔭 Learning">
                            <span className="emoji">🔭</span> Learning
                        </option>
                        <option value="🤝 Social">
                            <span className="emoji">🤝</span> Social
                        </option>
                        <option value="✈️ Travel">
                            <span className="emoji">✈️</span> Travel
                        </option>
                        <option value="🧘 Wellness">
                            <span className="emoji">🧘</span> Wellness
                        </option>
                        <option value="🎤 Music">
                            <span className="emoji">🎤</span> Music
                        </option>
                        <option value="🎉 Party">
                            <span className="emoji">🎉</span> Party
                        </option>
                        <option value="🍺 Bar">
                            <span className="emoji">🍺</span> Bar
                        </option>
                    </select>
                </div>
                <label htmlFor="eventDate" className="date-picker-label-ce">
                    start
                </label>
                <div className="date-picker-container-ce">
                    <DatePicker
                        id="eventDate"
                        selected={eventDate}
                        onChange={handleEventDateChange}
                        showTimeSelect
                        timeFormat="h:mm aa"
                        timeIntervals={15}
                        dateFormat="MMM d, yyyy h:mm aa"
                        className="date-picker-ce"
                        minDate={new Date()}
                        required
                    />
                </div>
                <label htmlFor="eventEndDate" className="date-picker-label-ce">
                    end
                </label>
                <div className="date-picker-container-ce">
                    <DatePicker
                        id="eventEndDate"
                        selected={eventEndDate}
                        onChange={(date) => setEventEndDate(date)}
                        showTimeSelect
                        timeFormat="h:mm aa"
                        timeIntervals={15}
                        dateFormat="MMM d, yyyy h:mm aa"
                        className="date-picker-ce"
                        minDate={eventDate}
                        disabled={!eventDate}
                    />
                </div>
                <div className="location-toggle-container-ce">
                    <div className="tooltip-ce">
                        <label htmlFor="publicEventToggle">
                            <span className="emoji">✨</span> public event?
                        </label>
                        <span className="tooltiptext-ce">
                            When enabled, everyone in the selected city will be
                            able to view the event.
                        </span>
                    </div>
                    <label className="switch-ce">
                        <input
                            type="checkbox"
                            id="publicEventToggle"
                            checked={isPublicEvent}
                            onChange={handlePublicToggle}
                        />
                        <span className="slider-ce"></span>
                    </label>
                </div>
                {isPublicEvent && (
                    <select
                        id="eventLocation"
                        value={selectedCity}
                        onChange={handleCityChange}
                        className="event-location-select-ce"
                    >
                        <option value="">Select a city 🌆</option>
                        <option value="Austin, TX">Austin, TX</option>
                        <option value="New York, NY">New York, NY</option>
                        <option value="Los Angeles, CA">Los Angeles, CA</option>
                        <option value="Chicago, IL">Chicago, IL</option>
                        <option value="Houston, TX">Houston, TX</option>
                        <option value="Phoenix, AZ">Phoenix, AZ</option>
                        <option value="Philadelphia, PA">
                            Philadelphia, PA
                        </option>
                        <option value="San Antonio, TX">San Antonio, TX</option>
                        <option value="San Diego, CA">San Diego, CA</option>
                        <option value="Dallas, TX">Dallas, TX</option>
                        <option value="San Jose, CA">San Jose, CA</option>
                        <option value="Jacksonville, FL">
                            Jacksonville, FL
                        </option>
                        <option value="Fort Worth, TX">Fort Worth, TX</option>
                        <option value="Columbus, OH">Columbus, OH</option>
                        <option value="Charlotte, NC">Charlotte, NC</option>
                        <option value="San Francisco, CA">
                            San Francisco, CA
                        </option>
                        <option value="Indianapolis, IN">
                            Indianapolis, IN
                        </option>
                        <option value="Seattle, WA">Seattle, WA</option>
                        <option value="Denver, CO">Denver, CO</option>
                        <option value="Washington, DC">Washington, DC</option>
                        <option value="Boston, MA">Boston, MA</option>
                        <option value="El Paso, TX">El Paso, TX</option>
                        <option value="Nashville, TN">Nashville, TN</option>
                        <option value="Detroit, MI">Detroit, MI</option>
                        <option value="Oklahoma City, OK">
                            Oklahoma City, OK
                        </option>
                        <option value="Portland, OR">Portland, OR</option>
                        <option value="Las Vegas, NV">Las Vegas, NV</option>
                        <option value="Memphis, TN">Memphis, TN</option>
                        <option value="Louisville, KY">Louisville, KY</option>
                        <option value="Baltimore, MD">Baltimore, MD</option>
                        <option value="Milwaukee, WI">Milwaukee, WI</option>
                        <option value="Albuquerque, NM">Albuquerque, NM</option>
                        <option value="Tucson, AZ">Tucson, AZ</option>
                        <option value="Fresno, CA">Fresno, CA</option>
                        <option value="Sacramento, CA">Sacramento, CA</option>
                        <option value="Mesa, AZ">Mesa, AZ</option>
                        <option value="Kansas City, MO">Kansas City, MO</option>
                        <option value="Atlanta, GA">Atlanta, GA</option>
                        <option value="Omaha, NE">Omaha, NE</option>
                        <option value="Raleigh, NC">Raleigh, NC</option>
                        <option value="Miami, FL">Miami, FL</option>
                        <option value="Long Beach, CA">Long Beach, CA</option>
                        <option value="Virginia Beach, VA">
                            Virginia Beach, VA
                        </option>
                        <option value="Oakland, CA">Oakland, CA</option>
                        <option value="Minneapolis, MN">Minneapolis, MN</option>
                        <option value="Tulsa, OK">Tulsa, OK</option>
                        <option value="Arlington, TX">Arlington, TX</option>
                        <option value="New Orleans, LA">New Orleans, LA</option>
                        <option value="Wichita, KS">Wichita, KS</option>
                        <option value="Cleveland, OH">Cleveland, OH</option>
                        <option value="Tampa, FL">Tampa, FL</option>
                        <option value="Bakersfield, CA">Bakersfield, CA</option>
                        <option value="Aurora, CO">Aurora, CO</option>
                        <option value="Honolulu, HI">Honolulu, HI</option>
                        <option value="Anaheim, CA">Anaheim, CA</option>
                        <option value="Santa Ana, CA">Santa Ana, CA</option>
                        <option value="Corpus Christi, TX">
                            Corpus Christi, TX
                        </option>
                        <option value="Riverside, CA">Riverside, CA</option>
                        <option value="St. Louis, MO">St. Louis, MO</option>
                        <option value="Lexington, KY">Lexington, KY</option>
                        <option value="Stockton, CA">Stockton, CA</option>
                        <option value="Pittsburgh, PA">Pittsburgh, PA</option>
                        <option value="Anchorage, AK">Anchorage, AK</option>
                        <option value="Cincinnati, OH">Cincinnati, OH</option>
                        <option value="Henderson, NV">Henderson, NV</option>
                        {/* Add more city options */}
                    </select>
                )}
                <div className="event-details-section-ce">
                    <h2 className="event-details-title-ce">
                        details <span className="emoji">💭</span>
                    </h2>
                    <textarea
                        id="eventDescription"
                        value={eventDescription}
                        onChange={(e) => setEventDescription(e.target.value)}
                        className="event-description-textarea-ce"
                        placeholder="..."
                    ></textarea>
                    <div className="photo-input-container-ce">
                        <input
                            type="file"
                            id="eventPhoto"
                            accept="image/*"
                            onChange={handlePhotoChange}
                            className="event-photo-input-ce"
                            style={{ display: "none" }}
                        />
                        {!previewUrl && (
                            <label
                                htmlFor="eventPhoto"
                                className="photo-input-label-ce"
                            >
                                add a photo <span className="emoji">🖼️</span>
                            </label>
                        )}
                        {previewUrl && (
                            <div className="photo-preview-container-ce">
                                <img
                                    src={previewUrl}
                                    alt="Event"
                                    className="photo-preview-ce"
                                />
                                <button
                                    type="button"
                                    className="photo-delete-btn-ce"
                                    onClick={handlePhotoDelete}
                                >
                                    &times;
                                </button>
                            </div>
                        )}
                    </div>
                    <div className="event-url-container-ce">
                        <input
                            type="url"
                            id="eventUrl"
                            value={eventLink}
                            onChange={(e) => setEventLink(e.target.value)}
                            className="event-url-input-ce"
                            placeholder="🔗 ur website here"
                        />
                    </div>
                    <div className="event-address-container-ce">
                        <PlacesAutocomplete
                            value={eventAddress}
                            onChange={setEventAddress}
                            onSelect={handleAddressSelect}
                            className="places-autocomplete-ce"
                        >
                            {({
                                getInputProps,
                                suggestions,
                                getSuggestionItemProps,
                                loading,
                            }) => (
                                <div className="autocomplete-dropdown-container-ce">
                                    <input
                                        {...getInputProps({
                                            placeholder: "📍 address",
                                            className:
                                                "location-search-input-ce",
                                        })}
                                    />
                                    <div className="autocomplete-dropdown-ce">
                                        {loading && <div>Loading...</div>}
                                        {suggestions.map((suggestion) => {
                                            const className = suggestion.active
                                                ? "suggestion-item-ce suggestion-item--active-ce"
                                                : "suggestion-item-ce";
                                            return (
                                                <div
                                                    {...getSuggestionItemProps(
                                                        suggestion,
                                                        {
                                                            className,
                                                        }
                                                    )}
                                                    key={suggestion.placeId}
                                                >
                                                    {suggestion.description}
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            )}
                        </PlacesAutocomplete>
                    </div>
                </div>
                <button
                    type="submit"
                    className="submit-btn-ce"
                    disabled={isLoading}
                >
                    {isLoading ? (
                        <div className="loading-wheel"></div>
                    ) : (
                        <>
                            submit <span className="emoji">🤩</span>
                        </>
                    )}
                </button>
            </form>
        </div>
    );
};

export default CreateEventPage;
