import React from "react";
import { useNavigate } from "react-router-dom";
import LoginModal from "../components/LoginModal/LoginModal";

function Login() {
    const navigate = useNavigate();

    const handleLoginSuccess = (isNewUser) => {
        if (isNewUser) {
            // Delay navigation to allow Firestore updates to complete
            navigate("/profile");
        } else {
            navigate("/profile");
        }
    };

    return (
        <div>
            <LoginModal onLoginSuccess={handleLoginSuccess} isRSVP={false} />
        </div>
    );
}

export default Login;
