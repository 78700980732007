import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { signOut, onAuthStateChanged } from "firebase/auth";
import { auth } from "../../firebaseConfig";
import "./NavigationBar.css";
import socallogo from "../../assets/socallogo.svg";

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [user, setUser] = useState(null);
    const navigate = useNavigate();
    const dropdownRef = useRef(null);
    const hamburgerRef = useRef(null);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            setUser(user);
        });

        return () => {
            unsubscribe();
        };
    }, []);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleClickOutside = (event) => {
        if (
            dropdownRef.current &&
            !dropdownRef.current.contains(event.target) &&
            !hamburgerRef.current.contains(event.target)
        ) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleLogout = () => {
        signOut(auth)
            .then(() => {
                navigate("/login");
            })
            .catch((error) => {
                console.error("Sign out Error:", error);
            });
    };

    return (
        <nav>
            <div className="navbar">
                <div
                    className={`hamburger-menu ${isOpen ? "open" : ""}`}
                    onClick={toggleDropdown}
                    ref={hamburgerRef}
                >
                    <div className="bar"></div>
                    <div className="bar"></div>
                    <div className="bar"></div>
                </div>
                <div className="logo">
                    <img src={socallogo} alt="Logo" />
                </div>
            </div>
            <div
                className={`dropdown-menu ${isOpen ? "show" : ""}`}
                ref={dropdownRef}
            >
                <ul>
                    <li>
                        <Link to="/explore" onClick={toggleDropdown}>
                            ✨ explore
                        </Link>
                    </li>
                    <li>
                        <Link to="/create-event" onClick={toggleDropdown}>
                            ✏️ create
                        </Link>
                    </li>
                    {user && (
                        <li>
                            <Link to="/profile" onClick={toggleDropdown}>
                                🧳 profile
                            </Link>
                        </li>
                    )}
                    <li>
                        <Link to="/about" onClick={toggleDropdown}>
                            📜 privacy/tos
                        </Link>
                    </li>
                    <li>
                        <a
                            href="https://apps.apple.com/us/app/socal-the-social-calendar/id1640402726"
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={toggleDropdown}
                        >
                            🍎 download app
                        </a>
                    </li>
                    {user ? (
                        <li>
                            <button onClick={handleLogout}>🔑 Logout</button>
                        </li>
                    ) : (
                        <li>
                            <Link to="/login" onClick={toggleDropdown}>
                                🔑 Login
                            </Link>
                        </li>
                    )}
                </ul>
            </div>
        </nav>
    );
};

export default Navbar;
