import React, { useState, useEffect } from "react";
import {
    BrowserRouter,
    Route,
    Routes,
    useParams,
    useNavigate,
} from "react-router-dom";
import Home from "./Home";
import AppleAppSiteAssociation from "./AppleAppSiteAssociation";
import EventLandingPage from "./pages/EventLandingPage";
import EventExplorePage from "./pages/EventExplorePage";
import About from "./pages/About";
import Login from "./pages/Login";
import ProfilePage from "./pages/ProfilePage";
import CreateEventPage from "./pages/CreateEventPage";
import NavigationBar from "./components/NavigationBar/NavigationBar";
import GoogleMapsWrapper from "./components/GoogleMapsWrapper/GoogleMapsWrapper";
import DataDeletion from "./components/DataDeletion/DataDeletion";

function EventLandingPageWithParam() {
    const { eventId } = useParams();
    return <EventLandingPage eventId={eventId} />;
}

function App() {
    const navigate = useNavigate();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <GoogleMapsWrapper>
            <NavigationBar />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route
                    path="/apple-app-site-association"
                    element={<AppleAppSiteAssociation />}
                />
                <Route
                    path="/.well-known/apple-app-site-association"
                    element={<AppleAppSiteAssociation />}
                />
                <Route
                    path="/example-event"
                    element={<EventLandingPageWithParam />}
                />
                <Route
                    path="/event/:eventId"
                    element={<EventLandingPageWithParam />}
                />
                <Route path="/about" element={<About />} />
                <Route path="/explore" element={<EventExplorePage />} />
                <Route path="/login" element={<Login />} />
                <Route path="/profile" element={<ProfilePage />} />
                <Route path="/create-event" element={<CreateEventPage />} />
                <Route
                    path="/edit-event/:eventId"
                    element={
                        <CreateEventPage
                            eventData={location.state?.eventData}
                        />
                    }
                />
                <Route path="/login" element={<Login />} />
                <Route path="/event/:eventId" element={<EventLandingPage />} />
                <Route path="/data-deletion" element={<DataDeletion />} />{" "}
            </Routes>
        </GoogleMapsWrapper>
    );
}

export default function AppWrapper() {
    return (
        <BrowserRouter>
            <App />
        </BrowserRouter>
    );
}
