import React from "react";
import "./Home.css";
import "./iPhoneFrame.css";
import buttonImage from "./assets/dl-on-appstore.svg";
import Dark1 from "./assets/Dark1.png";
import Dark2 from "./assets/Dark2.png";
import Dark3 from "./assets/Dark3.png";
import Dark4 from "./assets/Dark4.png";
import EventExplorePage from "./pages/EventExplorePage";
import ExploreEventsButton from "./components/ExploreEventsButton/ExploreEventsButton";
import CreateEventButton from "./components/CreateEventButton/CreateEventButton";

function Home() {
    return (
        <div id="home">
            <ExploreEventsButton />
            <CreateEventButton />

            <div className="imageGallery">
                <div className="iphoneFramesContainer">
                    <img
                        src={Dark1}
                        alt="Dark1"
                        title=""
                        className="iphone14frame"
                    />
                    <img
                        src={Dark2}
                        alt="Dark2"
                        title=""
                        className="iphone14frame"
                    />
                    <img
                        src={Dark3}
                        alt="Dark3"
                        title=""
                        className="iphone14frame"
                    />
                    <img
                        src={Dark4}
                        alt="Dark4"
                        title=""
                        className="iphone14frame"
                    />
                </div>
                <div className="appStoreImageContainer">
                    <a
                        href="https://apps.apple.com/us/app/socal-the-social-calendar/id1640402726"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img
                            src={buttonImage}
                            alt="Button"
                            title=""
                            className="buttonImage"
                        />
                    </a>
                </div>
            </div>

            <div className="paragraphContainer">
                <div className="roundedRectangle">
                    <p>
                        Meet socal, the ultimate calendar app that streamlines
                        your social life. With socal, you can create, share, and
                        explore local events happening in your area! Sign up
                        with your name, phone number, and birthday to enjoy our
                        user-friendly interface.
                    </p>
                    <p>
                        Forget group chats and messy email calendars. socal puts
                        all your social plans in one spot, making it easier than
                        ever to stay connected.
                    </p>
                    <p>
                        Using your phone number, we'll link you with friends on
                        socal, so you're always updated on upcoming events. From
                        dinner parties to weekend trips, socal's got you
                        covered.
                    </p>
                    <p>
                        Ready for a change? Download socal now and enjoy
                        hassle-free event planning and smooth social
                        connections. Upgrade your social life with socal today.
                    </p>
                    <p>
                        Download the socal app today using the "available on the
                        app store button" above!
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Home;
