import React from "react";

function ObfuscateEmail({ email }) {
  const obfuscateEmail = (email) => {
    let address = "";
    for (let i = 0; i < email.length; i++) {
      address += "&#" + email.charCodeAt(i) + ";";
    }
    return address;
  };

  const obfuscated = obfuscateEmail(email);

  return (
    <a href={`mailto:${email}`}>
      <span dangerouslySetInnerHTML={{ __html: obfuscated }} />
    </a>
  );
}

export default ObfuscateEmail;
