import React from "react";
import "./About.css";
import ObfuscateEmail from "../utilities/ObfuscateEmail";

function About() {
    return (
        <div id="about">
            <div className="about">
                <div className="paragraphContainer">
                    <div className="roundedRectangle">
                        <h1>Terms of Service</h1>

                        <h2>1. Acceptance of Terms</h2>
                        <p>
                            These Terms of Service (the "Agreement") constitute
                            a legal agreement between you and Socal. By
                            accessing or using the Socal mobile application (the
                            "App"), you agree to be bound by the terms and
                            conditions of this Agreement. If you do not agree to
                            these terms, you may not use the App.
                        </p>

                        <h2>2. User Conduct</h2>
                        <p>
                            You agree to use the App in accordance with all
                            applicable laws and regulations. You further agree
                            not to:
                        </p>
                        <ul>
                            <li>
                                Modify or attempt to modify the App in any way
                            </li>
                            <li>Use the App for any unlawful purpose</li>
                            <li>
                                Impersonate any person or entity or falsely
                                represent your affiliation with any person or
                                entity
                            </li>
                            <li>
                                Use the App to transmit any content that is
                                harmful, threatening, abusive, harassing,
                                defamatory, obscene, or otherwise objectionable
                            </li>
                        </ul>

                        <h2>3. Intellectual Property</h2>
                        <p>
                            The App and its content are owned by Socal and are
                            protected by copyright, trademark, and other laws.
                            You may not reproduce, distribute, modify, or create
                            derivative works of any part of the App without our
                            prior written consent.
                        </p>

                        <h2>4. Limitation of Liability</h2>
                        <p>
                            Socal is not liable for any damages, including but
                            not limited to, direct, indirect, incidental,
                            consequential, or punitive damages arising out of
                            your use of the App.
                        </p>

                        <h2>5. Indemnification</h2>
                        <p>
                            You agree to indemnify and hold harmless Socal and
                            its affiliates, officers, directors, employees, and
                            agents from and against any and all claims,
                            liabilities, damages, losses, or expenses, including
                            reasonable attorneys' fees and costs, arising out of
                            or in any way related to your use of the App.
                        </p>

                        <h2>6. Termination</h2>
                        <p>
                            Socal may terminate this Agreement at any time
                            without notice if you breach any of the terms and
                            conditions contained herein. Upon termination, you
                            must immediately stop using the App.
                        </p>

                        <h2>7. Privacy Policy</h2>
                        <p>
                            By using the App, you agree to the terms of our
                            Privacy Policy, which is incorporated by reference
                            into this Agreement. You can view the Privacy Policy
                            by scrolling down.
                        </p>

                        <h2>8. Changes to this Agreement</h2>
                        <p>
                            Socal reserves the right to modify this Agreement at
                            any time. Your continued use of the App following
                            any such modification constitutes your agreement to
                            be bound by the modified Agreement.
                        </p>

                        <h2>9. Governing Law and Jurisdiction</h2>
                        <p>
                            This Agreement shall be governed by and construed in
                            accordance with the laws of the government of Texas.
                            Any legal action or proceeding arising out of or
                            relating to this Agreement or the use of the App
                            shall be exclusively in the state or federal courts
                            located in Texas.
                        </p>
                        <h2>10. Contact Us</h2>
                        <p>
                            If you have any questions or concerns about this
                            Agreement or the use of the App, please contact us
                            at <ObfuscateEmail email="tag@socal.day" /> or{" "}
                            <ObfuscateEmail email="braxton@socal.day" />.
                        </p>
                    </div>
                </div>
                <div className="paragraphContainer">
                    <div className="roundedRectangle">
                        <h1>Privacy Policy</h1>
                        <p>
                            This Privacy Policy describes how your personal
                            information is collected, used, and shared when you
                            use the Socal App (the "App").
                        </p>

                        <h2>1. Information we collect</h2>
                        <ul>
                            <li>Your name and birthday</li>
                            <li>Your phone number</li>
                            <li>
                                Access to your photos and user phone contacts
                            </li>
                        </ul>

                        <h2>2. How we use your information</h2>
                        <ul>
                            <li>Create and manage your user account</li>
                            <li>
                                Allow you to create, send, and RSVP to events
                            </li>
                            <li>
                                Enable communication between you and your
                                friends on the App
                            </li>
                            <li>
                                Improve the App's functionality and user
                                experience
                            </li>
                        </ul>

                        <p>
                            We do not share your information with any third
                            parties or use it for any other purpose than
                            providing the App's functionality.
                        </p>

                        <h2>3. Data storage and security</h2>
                        <p>
                            We use standard encryption provided by Firebase to
                            protect your personal information. Your data is
                            stored securely in our databases for as long as it
                            is necessary to provide you with the App's services.
                        </p>

                        <h2>4. Intellectual Property</h2>
                        <p>
                            The App and its content are owned by us and are
                            protected by copyright, trademark, and other laws.
                            You may not reproduce, distribute, modify, or create
                            derivative works of any part of the App without our
                            prior written consent.
                        </p>

                        <h2>5. Liability</h2>
                        <p>
                            We make no representations or warranties of any
                            kind, express or implied, regarding the App or its
                            content. We are not liable for any damages,
                            including but not limited to, direct, indirect,
                            incidental, consequential, or punitive damages
                            arising out of your use of the App.
                        </p>

                        <h2>6. Dispute Resolution</h2>
                        <p>
                            Any dispute arising out of or relating to this
                            Privacy Policy or the use of the App shall be
                            resolved through binding arbitration in accordance
                            with the rules of the American Arbitration
                            Association. The arbitration shall be conducted in
                            the English language and held in the United States.
                            The arbitrator's award shall be final and binding,
                            and may be entered as a judgment in any court of
                            competent jurisdiction.
                        </p>

                        <h2>7. Prohibited Activities</h2>
                        <p>
                            Users are prohibited from modifying or attempting to
                            modify the App in any way. Any unauthorized use of
                            the App, including but not limited to hacking,
                            reverse-engineering, or attempting to access source
                            code, is strictly prohibited.
                        </p>

                        <h2>8. Children's privacy</h2>
                        <p>
                            The App is not intended for children under the age
                            of 13. We do not knowingly collect or store personal
                            information from children under the age of 13. If we
                            become aware that we have collected personal
                            information from a child under the age of 13, we
                            will delete the information as soon as possible.
                        </p>

                        <h2>9. Changes to this Privacy Policy</h2>
                        <p>
                            We reserve the right to modify this Privacy Policy
                            at any time. If we make any material changes to this
                            policy, we will notify you by email or through the
                            App.
                        </p>

                        <h2>10. Contact Us</h2>
                        <p>
                            If you have any questions or concerns about our
                            privacy policy or practices, please contact us at{" "}
                            <ObfuscateEmail email="tag@socal.day" /> or{" "}
                            <ObfuscateEmail email="braxton@socal.day" />.
                        </p>
                    </div>
                </div>
            </div>
            <div className="paragraphContainer">
                <div className="roundedRectangle">
                    <h1>Data Deletion Instructions</h1>
                    <p>
                        If you wish to have your account and all associated data
                        deleted from the Socal app, please follow these steps:
                    </p>
                    <ol>
                        <li>
                            Compose an email to{" "}
                            <ObfuscateEmail email="tag@socal.day" />.
                        </li>
                        <li>
                            In the subject line, write "Data Deletion Request".
                        </li>
                        <li>
                            In the body of the email, provide your Socal
                            username.
                        </li>
                        <li>Send the email.</li>
                    </ol>
                    <p>
                        Once we receive your email, we will manually process
                        your request and delete all data associated with your
                        account. Please allow up to 48 hours for the process to
                        be completed. You will receive a confirmation email once
                        your data has been deleted.
                    </p>
                    <p>
                        Please note that this is a temporary solution. We are
                        working on implementing an automated data deletion
                        process within the app for your convenience.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default About;
