import React, { useState, useEffect, useRef } from "react";
import {
    RecaptchaVerifier,
    signInWithPhoneNumber,
    signInWithCredential,
    PhoneAuthProvider,
    onAuthStateChanged,
    signOut,
} from "firebase/auth";
import { useNavigate } from "react-router-dom";
import {
    doc,
    getDoc,
    setDoc,
    Timestamp,
    collection,
    query,
    where,
    getDocs,
} from "firebase/firestore";
import { auth, database } from "../../firebaseConfig";
import "./LoginModal.css";

function LoginModal({ onLoginSuccess, eventId, isRSVP }) {
    const [phoneNumber, setPhoneNumber] = useState("");
    const [verificationCode, setVerificationCode] = useState("");
    const [verificationId, setVerificationId] = useState(null);
    const [user, setUser] = useState(null);
    const [userProfile, setUserProfile] = useState(null);
    const [showNewUserForm, setShowNewUserForm] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [showRecaptcha, setShowRecaptcha] = useState(false);

    // Additional states for form fields
    const [userHandle, setUserHandle] = useState("");
    const [name, setUserName] = useState("");
    const [birthday, setBirthday] = useState("");
    const [maxBirthday, setMaxBirthday] = useState("");

    const navigate = useNavigate();
    const firstInputRef = useRef(null);

    useEffect(() => {
        console.log("Effect setup started"); // Debug: Indicate useEffect start

        // Calculate the maximum birthday date
        const calculateMaxBirthday = () => {
            const today = new Date();
            const maxYear = today.getFullYear() - 17;
            const maxMonth = today.getMonth() + 1; // JavaScript months are 0-indexed, add 1 for a human-readable format
            const maxDay = today.getDate();
            const maxDate = `${maxYear}-${maxMonth
                .toString()
                .padStart(2, "0")}-${maxDay.toString().padStart(2, "0")}`;
            setMaxBirthday(maxDate); // Assuming you have a state [maxBirthday, setMaxBirthday] to hold this value
        };

        const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
            console.log("Auth state changed", currentUser); // Debug: Log auth state changes
            setIsLoading(true); // Start loading
            setUser(currentUser);
            if (currentUser) {
                const userRef = doc(database, "users", currentUser.uid);
                const userSnap = await getDoc(userRef);
                if (userSnap.exists()) {
                    console.log("User profile found");
                    setUserProfile(userSnap.data());
                    setShowNewUserForm(false);
                    // navigate("/profile"); // Move navigation here, only if profile exists
                } else {
                    console.log("No user profile found!");
                    setUserProfile(null);
                    setShowNewUserForm(true);
                    // Do not navigate to profile if no profile exists
                }
            } else {
                setUserProfile(null);
                setShowNewUserForm(false);
            }
            setIsLoading(false); // End loading after profile check
        });

        // Set showRecaptcha to true after a short delay to ensure the DOM is ready
        const timer = setTimeout(() => {
            setShowRecaptcha(true);
        }, 100);

        calculateMaxBirthday(); // Add this call to calculate and set the max birthday

        return () => {
            console.log("Cleaning up effect"); // Debug: Log cleanup actions
            unsubscribe();
            clearTimeout(timer);
            if (window.recaptchaVerifier) {
                window.recaptchaVerifier.clear();
                window.recaptchaVerifier = null;
            }
        };
    }, [navigate]);

    const setUpRecaptcha = () => {
        const recaptchaContainer = document.getElementById(
            "recaptcha-container"
        );
        if (recaptchaContainer) {
            if (window.recaptchaVerifier) {
                window.recaptchaVerifier.clear();
            }

            window.recaptchaVerifier = new RecaptchaVerifier(
                "recaptcha-container",
                { size: "invisible" },
                auth
            );
            window.recaptchaVerifier.render().catch((error) => {
                console.error("Recaptcha Render Error:", error);
            });
        }
    };

    const isValidPhoneNumber = (number) =>
        number.length === 10 && number.every((digit) => /^\d$/.test(digit));

    const handleSendCode = () => {
        if (!isValidPhoneNumber(phoneNumber)) {
            console.error(
                "Invalid phone number. Please enter a 10-digit phone number."
            );
            return;
        }

        const formattedPhoneNumber = `+1${phoneNumber.join("")}`;
        setUpRecaptcha();

        const appVerifier = window.recaptchaVerifier;

        signInWithPhoneNumber(auth, formattedPhoneNumber, appVerifier)
            .then((confirmationResult) => {
                setVerificationId(confirmationResult.verificationId);
            })
            .catch((error) => {
                console.error("Sign-in Error:", error);
                if (error.code === "auth/too-many-requests") {
                    alert(
                        "Too many requests. Please try again later or contact support."
                    );
                } else {
                    alert("An error occurred. Please try again.");
                }
            });
    };

    useEffect(() => {
        if (verificationId && firstInputRef.current) {
            firstInputRef.current.focus();
        }
    }, [verificationId]);

    const handleVerifyCode = () => {
        const code = verificationCode.join(""); // Join the array elements into a single string
        const credential = PhoneAuthProvider.credential(verificationId, code);
        signInWithCredential(auth, credential)
            .then(async (result) => {
                console.log("User signed in!", result.user);
                const userRef = doc(database, "users", result.user.uid);
                const userSnap = await getDoc(userRef);
                if (userSnap.exists()) {
                    if (onLoginSuccess) {
                        onLoginSuccess(false); // Existing user
                    }
                } else {
                    setShowNewUserForm(true);
                }
            })
            .catch((error) => {
                console.error("Verification Error:", error);
            });
    };

    const saveNewUserDetails = async () => {
        if (user) {
            if (!birthday) {
                alert("Please select your birthday.");
                return; // Stop the function execution if no birthday is selected
            }

            // Validation for userHandle
            const isValidUserHandle = /^[a-zA-Z0-9_]{3,15}$/.test(userHandle);
            if (!isValidUserHandle) {
                alert(
                    "User handle must be 3-15 characters long and contain no spaces or special characters."
                );
                return; // Stop execution if validation fails
            }

            // Validation for name
            const isValidName = name.length >= 3 && name.length <= 15;
            if (!isValidName) {
                alert("Name must be between 3 and 15 characters.");
                return; // Stop execution if validation fails
            }

            // Convert the birthday string to a Date object
            const birthdayDate = new Date(birthday);

            // Ensure there is an authenticated user
            const userRef = doc(database, "users", user.uid); // Reference to the user's document in the 'users' collection

            try {
                const usersRef = collection(database, "users");
                const q = query(
                    usersRef,
                    where("userHandle", "==", userHandle.trim())
                );
                const querySnapshot = await getDocs(q);

                if (!querySnapshot.empty) {
                    alert(
                        "User handle is already taken. Please choose a different one."
                    );
                    return;
                }

                const phoneNumberString = phoneNumber.join("");

                await setDoc(userRef, {
                    profileImageUrl:
                        "https://firebasestorage.googleapis.com:443/v0/b/socal-e440e.appspot.com/o/uploaded_images%2FE303BBFD-27B5-414C-B91B-FE7EC86788F7?alt=media&token=ac44770c-d1b8-4bb4-97f0-1e5a0c508b63",
                    phoneNumber: `+1${phoneNumberString}`,
                    name: name,
                    uid: user.uid,
                    userHandle: userHandle,
                    birthday: Timestamp.fromDate(birthdayDate), // Convert to Firestore Timestamp
                    // Add any other relevant user details here
                });
                console.log("New user details saved successfully");

                setUserProfile({ userHandle, name, birthday: birthdayDate });
                setShowNewUserForm(false);

                if (isRSVP) {
                    // Add the user as an attendee only if isRSVP is true
                    try {
                        const eventRef = doc(database, "events", eventId);
                        const eventSnap = await getDoc(eventRef);
                        const eventData = eventSnap.data();

                        if (
                            eventData &&
                            eventData.attending &&
                            !eventData.attending.includes(auth.currentUser.uid)
                        ) {
                            const updatedAttendingUserIds = [
                                ...eventData.attending,
                                auth.currentUser.uid,
                            ];
                            await updateDoc(eventRef, {
                                attending: updatedAttendingUserIds,
                            });
                        }
                    } catch (error) {
                        console.error(
                            "Error updating event attendance:",
                            error
                        );
                    }
                }

                if (onLoginSuccess) {
                    onLoginSuccess(true);
                }
            } catch (error) {
                console.error("Error saving new user details:", error);
            }
        } else {
            console.error(
                "No authenticated user found. Cannot save new user details."
            );
        }
    };

    const handleVerificationCodeChange = (e, index) => {
        const value = e.target.value.replace(/\D/g, "");

        if (value === "" || /^\d$/.test(value)) {
            const newCode = [...verificationCode];
            newCode[index] = value;
            setVerificationCode(newCode);

            if (e.key === "Backspace" && index > 0 && value === "") {
                const prevIndex = index - 1;
                const prevInput = document.getElementsByClassName(
                    "verification-code-input"
                )[prevIndex];
                prevInput.focus();
                prevInput.setSelectionRange(1, 1);
            } else if (value !== "" && index < 5) {
                const nextIndex = index + 1;
                const nextInput = document.getElementsByClassName(
                    "verification-code-input"
                )[nextIndex];
                nextInput.focus();
            }
        }
    };

    const handlePhoneNumberChange = (e, index) => {
        const value = e.target.value.replace(/\D/g, "");

        if (value === "" || /^\d$/.test(value)) {
            const newNumber = [...phoneNumber];
            newNumber[index] = value;
            setPhoneNumber(newNumber);

            if (e.key === "Backspace" && index > 0 && value === "") {
                const prevIndex = index - 1;
                const prevInput =
                    document.getElementsByClassName("phone-number-input")[
                        prevIndex
                    ];
                prevInput.focus();
                prevInput.setSelectionRange(1, 1);
            } else if (value !== "" && index < 9) {
                const nextIndex = index + 1;
                const nextInput =
                    document.getElementsByClassName("phone-number-input")[
                        nextIndex
                    ];
                nextInput.focus();
            }
        }
    };

    const handleLogout = () => {
        signOut(auth)
            .then(() => {
                console.log("User signed out.");
                setVerificationId(null);
                setVerificationCode("");
                setPhoneNumber("");
                setUserProfile(null); // Clear user profile on logout

                // Clear and nullify the reCAPTCHA verifier to ensure it can be reinitialized
                if (window.recaptchaVerifier) {
                    window.recaptchaVerifier.clear();
                    window.recaptchaVerifier = null;
                }

                // Delaying the re-setup of reCAPTCHA might help ensure the DOM is ready
                setTimeout(() => setUpRecaptcha(), 0);
            })
            .catch((error) => {
                console.error("Sign out Error:", error);
            });
    };

    if (isLoading) {
        // Render progress spinner while loading
        return (
            <div className="modal-content">
                <div className="loader"></div>{" "}
                {/* You can replace this with a spinner component or animation */}
            </div>
        );
    }

    if (user) {
        if (!userProfile) {
            // Render the new user form if the authenticated user does not have a profile
            return (
                <div className="modal-content">
                    <div className="input-label">sign up 🤩</div>

                    <div className="form-input-container">
                        <input
                            type="text"
                            value={userHandle}
                            onChange={(e) => setUserHandle(e.target.value)}
                            placeholder="choose ur @"
                            className="form-input"
                            maxLength="15" // This limits the user to entering a maximum of 15 characters
                        />
                        <input
                            type="text"
                            value={name}
                            onChange={(e) => setUserName(e.target.value)}
                            placeholder="name?"
                            className="form-input"
                            maxLength="15" // This limits the user to entering a maximum of 15 characters
                        />
                        <div className="input-label">bday? 🎂</div>
                        <input
                            type="date"
                            value={birthday}
                            onChange={(e) => setBirthday(e.target.value)}
                            max={maxBirthday}
                            className="form-input"
                            placeholder="🎂 Birthday"
                        />
                        <button
                            onClick={saveNewUserDetails}
                            className="form-submit-button"
                        >
                            create account 🥳
                        </button>
                    </div>
                </div>
            );
        } else {
            // Render the welcome message and logout button if the user profile exists
            return (
                <div className="modal-content">
                    <div>Welcome, {userProfile.userHandle || "Anonymous"}</div>
                    <button onClick={handleLogout} className="logout-button">
                        Logout
                    </button>
                </div>
            );
        }
    }

    return (
        <>
            {showNewUserForm ? (
                // Render the form for new user details if showNewUserForm is true
                <div className="modal-content">
                    <h2>New User Setup</h2>
                    <div className="form-input-container">
                        <input
                            type="text"
                            value={userHandle}
                            onChange={(e) => setUserHandle(e.target.value)}
                            placeholder="choose ur @"
                            className="form-input"
                        />
                        <input
                            type="text"
                            value={name}
                            onChange={(e) => setUserName(e.target.value)}
                            placeholder="name?"
                            className="form-input"
                        />
                        <input
                            type="date"
                            value={birthday}
                            onChange={(e) => setBirthday(e.target.value)}
                            max={maxBirthday}
                            className="form-input"
                            placeholder="🎂 Birthday"
                        />
                        <button
                            onClick={saveNewUserDetails}
                            className="form-submit-button"
                        >
                            Create Account
                        </button>
                    </div>
                </div>
            ) : (
                // Existing logic for phone number input and verification code input
                <div className="modal-content">
                    {!verificationId ? (
                        <>
                            <h2>🥳 login or sign up!</h2>

                            <div className="phone-input-container">
                                <div className="country-code">
                                    <span className="country-flag">🇺🇸</span>
                                    <span>+1</span>
                                </div>
                                {Array(10)
                                    .fill(null)
                                    .map((_, index) => (
                                        <input
                                            key={index}
                                            type="text"
                                            maxLength="1"
                                            value={phoneNumber[index] || ""}
                                            onChange={(e) =>
                                                handlePhoneNumberChange(
                                                    e,
                                                    index
                                                )
                                            }
                                            onKeyDown={(e) =>
                                                handlePhoneNumberChange(
                                                    e,
                                                    index
                                                )
                                            }
                                            className="phone-number-input"
                                        />
                                    ))}
                            </div>

                            <button
                                onClick={handleSendCode}
                                disabled={phoneNumber.length !== 10}
                                className="send-code-button"
                            >
                                📲 text me
                            </button>
                        </>
                    ) : (
                        <div>
                            <div className="verification-code-container">
                                {Array(6)
                                    .fill(null)
                                    .map((_, index) => (
                                        <input
                                            key={index}
                                            type="text"
                                            maxLength="1"
                                            value={
                                                verificationCode[index] || ""
                                            }
                                            onChange={(e) =>
                                                handleVerificationCodeChange(
                                                    e,
                                                    index
                                                )
                                            }
                                            onKeyDown={(e) =>
                                                handleVerificationCodeChange(
                                                    e,
                                                    index
                                                )
                                            }
                                            className="verification-code-input"
                                            ref={
                                                index === 0
                                                    ? firstInputRef
                                                    : null
                                            }
                                        />
                                    ))}
                            </div>
                            <button
                                onClick={handleVerifyCode}
                                className="verify-code-button"
                                disabled={verificationCode.length !== 6}
                            >
                                Verify Code 🤖
                            </button>
                        </div>
                    )}
                </div>
            )}
            {showRecaptcha && <div id="recaptcha-container"></div>}
        </>
    );
}

export default LoginModal;
