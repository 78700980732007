import React from "react";
import { useNavigate } from "react-router-dom";
import "./ExploreEventsButton.css";

function ExploreEventsButton() {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate("/explore");
    };

    return (
        <button className="explore-events-button" onClick={handleClick}>
            explore events ✨
        </button>
    );
}

export default ExploreEventsButton;
