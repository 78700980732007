import React from "react";

function DataDeletion() {
    return (
        <div>
            <h1>Data Deletion Request</h1>
            <p>
                If you wish to have your account and all associated data deleted
                from the Socal app, please follow these steps:
            </p>
            <ol>
                <li>
                    Compose an email to{" "}
                    <a href="mailto:tag@socal.day">tag@socal.day</a>.
                </li>
                <li>In the subject line, write "Data Deletion Request".</li>
                <li>In the body of the email, provide your Socal username.</li>
                <li>Send the email.</li>
            </ol>
            <p>
                Once we receive your email, we will manually process your
                request and delete all data associated with your account. Please
                allow up to 48 hours for the process to be completed. You will
                receive a confirmation email once your data has been deleted.
            </p>
            <p>
                Please note that this is a temporary solution. We are working on
                implementing an automated data deletion process within the app
                for your convenience.
            </p>
        </div>
    );
}

export default DataDeletion;
