import React, { useState, useEffect } from "react";
import "./EventCard.css";
import {
    doc,
    getDoc,
    collection,
    getDocs,
    query,
    where,
} from "firebase/firestore";
import { database } from "../../firebaseConfig";
import { useNavigate } from "react-router-dom";

const EventCard = ({ item, onCardClick, attendingUserIds }) => {
    const [hostProfile, setHostProfile] = useState(null);
    const [attendingUsers, setAttendingUsers] = useState([]);
    const navigate = useNavigate();

    const formatDate = (date) => {
        return date
            ? new Intl.DateTimeFormat("en-US", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                  hour: "numeric",
                  minute: "numeric",
                  hour12: true,
              }).format(new Date(date.seconds * 1000))
            : null;
    };

    const handleCardClick = () => {
        if (onCardClick) {
            onCardClick(item.id);
        }
        navigate(`/event/${item.id}`);
    };

    const getCategoryEmoji = (category) => {
        if (category) {
            const [emoji] = category.split(" ");
            return emoji;
        }
        return "🌟";
    };

    useEffect(() => {
        const fetchHostProfile = async () => {
            try {
                const hostRef = doc(database, "users", item.uid);
                const hostSnap = await getDoc(hostRef);
                if (hostSnap.exists()) {
                    setHostProfile(hostSnap.data());
                }
            } catch (error) {
                console.error("Error fetching host profile:", error);
            }
        };

        fetchHostProfile();
    }, [item.uid]);

    useEffect(() => {
        const fetchProfiles = async () => {
            const usersRef = collection(database, "users");
            const filteredAttendingUserIds = attendingUserIds.filter(
                (uid) => uid !== item.uid
            );

            if (filteredAttendingUserIds.length > 0) {
                const usersQuery = query(
                    usersRef,
                    where("uid", "in", filteredAttendingUserIds.slice(0, 10))
                );
                const querySnapshot = await getDocs(usersQuery);
                const profiles = querySnapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                setAttendingUsers(profiles);
            } else {
                setAttendingUsers([]);
            }
        };

        if (attendingUserIds && attendingUserIds.length > 0) {
            fetchProfiles();
        } else {
            setAttendingUsers([]);
        }
    }, [attendingUserIds, item.uid]);

    return (
        <div className="event-card" onClick={handleCardClick}>
            <div className="event-card-top">
                <div className="event-card-profile-section">
                    <img
                        src={hostProfile?.profileImageUrl}
                        alt="Host Profile"
                        className="event-card-profile-image"
                    />
                    <div className="event-card-user-name">
                        {hostProfile?.name || "Anonymous"}
                    </div>
                    <div className="event-card-category">
                        {getCategoryEmoji(item.eventCategory)}
                    </div>
                </div>
                <div className="event-card-info">
                    <h2 className="event-card-title">
                        {item.eventTitle || "Untitled Event"}
                    </h2>
                    <time className="event-card-date">
                        {formatDate(item.eventDate)}
                        {item.eventEndDate &&
                            ` - ${formatDate(item.eventEndDate)}`}
                    </time>
                    {item.eventDescription && (
                        <p className="event-card-description">
                            {item.eventDescription}
                        </p>
                    )}
                    {attendingUsers.length > 0 && (
                        <div className="event-card-attending-users-container">
                            {attendingUsers.map((user, index) => (
                                <img
                                    key={user.uid}
                                    src={user.profileImageUrl}
                                    alt={`User ${user.name}`}
                                    className="event-card-attending-user-image"
                                    style={{
                                        zIndex: attendingUsers.length - index,
                                        left: `${index * 28}px`,
                                    }}
                                />
                            ))}
                        </div>
                    )}
                </div>
            </div>
            {item.eventImage && (
                <div className="event-card-image-container">
                    <img
                        src={item.eventImage}
                        alt="Event"
                        className="event-card-image"
                    />
                </div>
            )}
        </div>
    );
};

export default EventCard;
