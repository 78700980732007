import React, { useState, useEffect, useRef } from "react";
import { onAuthStateChanged } from "firebase/auth";
import {
    doc,
    getDoc,
    getDocs,
    query,
    where,
    collection,
    orderBy,
    startAfter,
    limit,
    updateDoc,
} from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { auth, database, storage } from "../firebaseConfig";
import { useNavigate } from "react-router-dom";
import "./ProfilePage.css";
import EventCard from "../components/EventCard/EventCard";
import CreateEventButton from "../components/CreateEventButton/CreateEventButton";
import LoadingWheel from "../components/LoadingWheel/LoadingWheel";

function ProfilePage() {
    const [userProfile, setUserProfile] = useState(null);
    const navigate = useNavigate();
    const [lastVisibleUpcoming, setLastVisibleUpcoming] = useState(null);
    const [lastVisiblePast, setLastVisiblePast] = useState(null);
    const fileInputRef = useRef(null);
    const [editingName, setEditingName] = useState(false);
    const [editingHandle, setEditingHandle] = useState(false);
    const [updatedName, setUpdatedName] = useState("");
    const [updatedHandle, setUpdatedHandle] = useState("");
    const [userUpcomingEvents, setUserUpcomingEvents] = useState([]);
    const [userPastEvents, setUserPastEvents] = useState([]);
    const [loadingUpcoming, setLoadingUpcoming] = useState(false);
    const [loadingPast, setLoadingPast] = useState(false);
    const [activeList, setActiveList] = useState("upcoming");
    const [hasMoreUpcomingEvents, setHasMoreUpcomingEvents] = useState(true);
    const [hasMorePastEvents, setHasMorePastEvents] = useState(true);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user) {
                const uid = user.uid;
                const userRef = doc(database, "users", uid);
                const userSnap = await getDoc(userRef);
                fetchUpcomingEvents(uid);
                if (userSnap.exists()) {
                    setUserProfile(userSnap.data());
                } else {
                    console.log("No user profile found!");
                }
            } else {
                console.log("No user is signed in.");
                navigate("/login");
            }
        });

        return () => unsubscribe();
    }, [navigate]);

    const fetchUpcomingEvents = async (uid, loadMore = false) => {
        setLoadingUpcoming(true);
        const currentDate = new Date();

        const baseQuery = query(
            collection(database, "events"),
            where("eventDate", ">=", currentDate),
            orderBy("eventDate", "asc"),
            limit(30),
            where("attending", "array-contains", uid)
        );

        const eventsQuery = loadMore
            ? query(baseQuery, startAfter(lastVisibleUpcoming))
            : baseQuery;

        try {
            const querySnapshot = await getDocs(eventsQuery);
            const events = querySnapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));

            if (loadMore) {
                setUserUpcomingEvents((prevEvents) => [
                    ...prevEvents,
                    ...events,
                ]);
            } else {
                setUserUpcomingEvents(events);
            }

            if (querySnapshot.docs.length > 0) {
                const lastVisibleDoc =
                    querySnapshot.docs[querySnapshot.docs.length - 1];
                setLastVisibleUpcoming(lastVisibleDoc);
            }

            setHasMoreUpcomingEvents(events.length === 30);
        } catch (error) {
            console.error("Error fetching upcoming events:", error);
        } finally {
            setLoadingUpcoming(false);
        }
    };

    const fetchPastEvents = async (uid, loadMore = false) => {
        setLoadingPast(true);
        const currentDate = new Date();

        const baseQuery = query(
            collection(database, "events"),
            where("eventDate", "<", currentDate),
            orderBy("eventDate", "desc"),
            limit(30),
            where("attending", "array-contains", uid)
        );

        const eventsQuery = loadMore
            ? query(baseQuery, startAfter(lastVisiblePast))
            : baseQuery;

        try {
            const querySnapshot = await getDocs(eventsQuery);
            const events = querySnapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));

            if (loadMore) {
                setUserPastEvents((prevEvents) => [...prevEvents, ...events]);
            } else {
                setUserPastEvents(events);
            }

            if (querySnapshot.docs.length > 0) {
                const lastVisibleDoc =
                    querySnapshot.docs[querySnapshot.docs.length - 1];
                setLastVisiblePast(lastVisibleDoc);
            }

            setHasMorePastEvents(events.length === 30);
        } catch (error) {
            console.error("Error fetching past events:", error);
        } finally {
            setLoadingPast(false);
        }
    };

    const handleProfileImageClick = () => {
        fileInputRef.current.click();
    };

    const handleProfileImageChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
            const storageRef = ref(
                storage,
                `profile_images/${userProfile.uid}`
            );
            await uploadBytes(storageRef, file);
            const downloadURL = await getDownloadURL(storageRef);
            const userRef = doc(database, "users", userProfile.uid);
            await updateDoc(userRef, { profileImageUrl: downloadURL });
            setUserProfile((prevProfile) => ({
                ...prevProfile,
                profileImageUrl: downloadURL,
            }));
        }
    };

    const handleEditName = (name) => {
        setUpdatedName(name);
        setEditingName(true);
    };

    const handleSaveName = async () => {
        if (updatedName.trim().length < 3 || updatedName.trim().length > 15) {
            alert("Name must be between 3 and 15 characters.");
            return;
        }

        try {
            const userRef = doc(database, "users", userProfile.uid);
            await updateDoc(userRef, { name: updatedName.trim() });
            setUserProfile((prevProfile) => ({
                ...prevProfile,
                name: updatedName.trim(),
            }));
            setEditingName(false);
        } catch (error) {
            console.error("Error updating name:", error);
        }
    };

    const handleEditHandle = (handle) => {
        setUpdatedHandle(handle);
        setEditingHandle(true);
    };

    const handleSaveHandle = async () => {
        const specialCharRegex = /^[a-zA-Z0-9]+$/;
        if (
            updatedHandle.trim().length < 3 ||
            updatedHandle.trim().length > 15 ||
            !specialCharRegex.test(updatedHandle.trim())
        ) {
            alert(
                "User handle must be between 3 and 15 characters and can only contain letters and numbers (no spaces)."
            );
            return;
        }

        try {
            const usersRef = collection(database, "users");
            const q = query(
                usersRef,
                where("userHandle", "==", updatedHandle.trim()),
                where("uid", "!=", userProfile.uid)
            );
            const querySnapshot = await getDocs(q);

            if (!querySnapshot.empty) {
                alert(
                    "User handle is already taken. Please choose a different one."
                );
                return;
            }

            const userRef = doc(database, "users", userProfile.uid);
            await updateDoc(userRef, { userHandle: updatedHandle.trim() });
            setUserProfile((prevProfile) => ({
                ...prevProfile,
                userHandle: updatedHandle.trim(),
            }));
            setEditingHandle(false);
        } catch (error) {
            console.error("Error updating handle:", error);
        }
    };

    return (
        <div className="profile-container">
            {userProfile ? (
                <>
                    <div className="profile-info">
                        <div className="profile-image-container">
                            <img
                                src={userProfile.profileImageUrl}
                                alt="Profile"
                                className="profile-image"
                                onClick={handleProfileImageClick}
                            />
                            <div
                                className="edit-text"
                                onClick={handleProfileImageClick}
                            >
                                edit
                            </div>
                        </div>
                        <input
                            type="file"
                            accept="image/*"
                            ref={fileInputRef}
                            style={{ display: "none" }}
                            onChange={handleProfileImageChange}
                        />
                        <div className="name-container">
                            {editingName ? (
                                <div className="edit-name">
                                    <input
                                        type="text"
                                        value={updatedName}
                                        onChange={(e) =>
                                            setUpdatedName(e.target.value)
                                        }
                                    />
                                    <div className="button-container">
                                        <button onClick={handleSaveName}>
                                            Save
                                        </button>
                                        <button
                                            onClick={() =>
                                                setEditingName(false)
                                            }
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            ) : (
                                <p
                                    onClick={() =>
                                        handleEditName(userProfile.name)
                                    }
                                >
                                    {userProfile.name}
                                </p>
                            )}
                        </div>
                        <div className="handle-container">
                            {editingHandle ? (
                                <div className="edit-handle">
                                    <input
                                        type="text"
                                        value={updatedHandle}
                                        onChange={(e) =>
                                            setUpdatedHandle(e.target.value)
                                        }
                                    />
                                    <div className="button-container">
                                        <button onClick={handleSaveHandle}>
                                            Save
                                        </button>
                                        <button
                                            onClick={() =>
                                                setEditingHandle(false)
                                            }
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            ) : (
                                <p
                                    onClick={() =>
                                        handleEditHandle(userProfile.userHandle)
                                    }
                                >
                                    @{userProfile.userHandle}
                                </p>
                            )}
                        </div>
                        <p>
                            <strong>📞</strong> {userProfile.phoneNumber}
                        </p>
                        <p>
                            <strong>
                                <span className="emoji">🎂</span>
                            </strong>{" "}
                            {userProfile.birthday.toDate().toDateString()}
                        </p>
                    </div>
                    <div className="create-event-button-wrapper">
                        <CreateEventButton />
                    </div>

                    <div className="event-list-toggle">
                        <button
                            className={`event-list-button ${
                                activeList === "upcoming" ? "active" : ""
                            }`}
                            onClick={() => {
                                setActiveList("upcoming");
                                if (userUpcomingEvents.length === 0) {
                                    fetchUpcomingEvents(userProfile.uid);
                                }
                            }}
                        >
                            <span className="emoji">🌟</span> upcoming
                        </button>
                        <button
                            className={`event-list-button ${
                                activeList === "past" ? "active" : ""
                            }`}
                            onClick={() => {
                                setActiveList("past");
                                if (userPastEvents.length === 0) {
                                    fetchPastEvents(userProfile.uid);
                                }
                            }}
                        >
                            ⌛️ past
                        </button>
                    </div>
                    {activeList === "upcoming" && (
                        <>
                            {userUpcomingEvents.length > 0 ? (
                                <div className="events-container">
                                    {userUpcomingEvents.map((event) => (
                                        <EventCard
                                            key={event.id}
                                            item={{
                                                ...event,
                                                profileImageUrl:
                                                    userProfile.profileImageUrl,
                                                name: userProfile.name,
                                            }}
                                            attendingUserIds={event.attending}
                                        />
                                    ))}
                                </div>
                            ) : (
                                <p>no upcoming events</p>
                            )}
                            {!loadingUpcoming && hasMoreUpcomingEvents && (
                                <button
                                    onClick={() =>
                                        fetchUpcomingEvents(
                                            userProfile.uid,
                                            true
                                        )
                                    }
                                    disabled={loadingUpcoming}
                                >
                                    {loadingUpcoming
                                        ? "Loading..."
                                        : "Load More"}
                                </button>
                            )}
                        </>
                    )}
                    {activeList === "past" && (
                        <>
                            {userPastEvents.length > 0 ? (
                                <div className="events-container">
                                    {userPastEvents.map((event) => (
                                        <EventCard
                                            key={event.id}
                                            item={{
                                                ...event,
                                                profileImageUrl:
                                                    userProfile.profileImageUrl,
                                                name: userProfile.name,
                                            }}
                                            attendingUserIds={event.attending}
                                        />
                                    ))}
                                </div>
                            ) : (
                                <p>
                                    nothing here{" "}
                                    <span className="emoji">🫡</span>
                                </p>
                            )}
                            {!loadingPast && hasMorePastEvents && (
                                <button
                                    onClick={() =>
                                        fetchPastEvents(userProfile.uid, true)
                                    }
                                    disabled={loadingPast}
                                >
                                    {loadingPast ? "Loading..." : "Load More"}
                                </button>
                            )}
                        </>
                    )}
                </>
            ) : (
                <p>Loading profile...</p>
            )}
        </div>
    );
}

export default ProfilePage;
