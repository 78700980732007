import React, { useState } from "react";
import "./EventOptionsModal.css";

const EventOptionsModal = ({
    isOpen,
    onClose,
    isHost,
    onEditEvent,
    onDeleteEvent,
    isAttending,
    onUnattendEvent,
}) => {
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

    if (!isOpen) return null;

    const handleDeleteClick = () => {
        setShowDeleteConfirmation(true);
    };

    const handleDeleteConfirm = () => {
        onDeleteEvent();
        setShowDeleteConfirmation(false);
        onClose();
    };

    const handleDeleteCancel = () => {
        setShowDeleteConfirmation(false);
    };

    return (
        <div className="event-options-modal-overlay">
            <div className="event-options-modal">
                <h2 className="event-options-modal-title">
                    what u wanna do 🤨
                </h2>
                {isHost ? (
                    <div className="event-options-modal-content">
                        <button
                            className="event-options-modal-button edit-button"
                            onClick={onEditEvent}
                        >
                            🧑‍💻 Edit Event
                        </button>
                        <button
                            className="event-options-modal-button delete-button"
                            onClick={handleDeleteClick}
                        >
                            🔥 Delete Event
                        </button>
                        {showDeleteConfirmation && (
                            <div className="delete-confirmation-modal">
                                <button
                                    className="delete-confirmation-button confirm-button"
                                    onClick={handleDeleteConfirm}
                                >
                                    Yes, delete
                                </button>
                                <button
                                    className="delete-confirmation-button cancel-button"
                                    onClick={handleDeleteCancel}
                                >
                                    Cancel
                                </button>
                            </div>
                        )}
                    </div>
                ) : isAttending ? (
                    <div className="event-options-modal-content">
                        <button
                            className="event-options-modal-button unattend-button"
                            onClick={onUnattendEvent}
                        >
                            🙅‍♂️ Unattend
                        </button>
                    </div>
                ) : (
                    <p className="event-options-modal-message">
                        No options available.
                    </p>
                )}
                <button className="event-options-modal-close" onClick={onClose}>
                    Close
                </button>
            </div>
        </div>
    );
};

export default EventOptionsModal;
