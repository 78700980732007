import React, { useEffect, memo, useRef, useState } from "react";
import PropTypes from "prop-types";
import "./DateDial.css";
import { collection, query, where, getDocs } from "firebase/firestore";
import { database } from "../../firebaseConfig";

const DateDial = memo(
    ({ weekDates, selectedDate, onDateSelect, selectedCity }) => {
        const scrollContainerRef = useRef();
        const [eventsMap, setEventsMap] = useState({});

        const formatDateForDial = (date) => {
            return date.toLocaleDateString("en-US", { weekday: "short" });
        };

        const formatDateNumber = (date) => {
            return date.getDate().toString().padStart(2, "0");
        };

        const fetchEventDates = async () => {
            try {
                const eventsRef = collection(database, "events");
                const q = query(
                    eventsRef,
                    where("eventLocation", "==", selectedCity)
                );

                const querySnapshot = await getDocs(q);
                const eventDates = querySnapshot.docs
                    .map((doc) => doc.data().eventDate)
                    .filter((eventDate) => eventDate)
                    .map((eventDate) => eventDate.toDate());

                return eventDates;
            } catch (error) {
                console.error("Error fetching event dates:", error);
                return [];
            }
        };

        useEffect(() => {
            const fetchAllEventDates = async () => {
                const eventDates = await fetchEventDates();

                const map = {};
                eventDates.forEach((eventDate) => {
                    const dateString = eventDate.toDateString();
                    map[dateString] = true;
                });

                setEventsMap(map);
            };

            fetchAllEventDates();
        }, [selectedCity]);

        useEffect(() => {
            if (weekDates.length > 0 && scrollContainerRef.current) {
                const selectedDayIndex = weekDates.findIndex(
                    (date) =>
                        date.toDateString() === selectedDate.toDateString()
                );
                const selectedDayElement =
                    scrollContainerRef.current.children[selectedDayIndex];

                if (selectedDayElement) {
                    const scrollPosition =
                        selectedDayElement.offsetLeft -
                        scrollContainerRef.current.offsetLeft +
                        selectedDayElement.offsetWidth / 2 -
                        scrollContainerRef.current.offsetWidth / 2;
                    scrollContainerRef.current.scrollLeft = scrollPosition;
                }
            }
        }, [weekDates, selectedDate]);

        const handleScroll = (e) => {
            const { scrollLeft, scrollWidth, clientWidth } = e.target;
            const buffer = 100;
            const atRightEnd = scrollWidth - scrollLeft === clientWidth;

            if (atRightEnd) {
                const lastDate = new Date(weekDates[weekDates.length - 1]);
                const newStartDate = new Date(
                    lastDate.setDate(lastDate.getDate() + 1)
                );
                const newEndDate = new Date(newStartDate);
                newEndDate.setDate(newEndDate.getDate() + 6);

                const newDates = generateDates(newStartDate, newEndDate);
                setWeekDates([...weekDates, ...newDates]);
            }
        };

        return (
            <div
                className="date-dial-container"
                ref={scrollContainerRef}
                onScroll={handleScroll}
            >
                {weekDates.map((date) => {
                    const isSelected =
                        selectedDate.toDateString() === date.toDateString();
                    const hasEvents = eventsMap[date.toDateString()];

                    return (
                        <div
                            key={date.toISOString()}
                            className={`date-dial-item ${
                                isSelected ? "selected" : ""
                            }`}
                            onClick={() => onDateSelect(date)}
                        >
                            <p className="date-day">
                                {formatDateForDial(date)}
                            </p>
                            <p className="date-number">
                                {formatDateNumber(date)}
                            </p>
                            {hasEvents && (
                                <div className="event-indicator"></div>
                            )}
                        </div>
                    );
                })}
            </div>
        );
    }
);

DateDial.propTypes = {
    weekDates: PropTypes.array.isRequired,
    selectedDate: PropTypes.instanceOf(Date).isRequired,
    onDateSelect: PropTypes.func.isRequired,
    selectedCity: PropTypes.string.isRequired,
};

export default DateDial;
