import React, { useState, useEffect, useRef } from "react";
import "./CityPicker.css"; // Adjust the path if necessary

function CityPicker({ selectedCity, onCitySelect }) {
    const [isOpen, setIsOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const dropdownRef = useRef(null);

    const cities = [
        "Austin, TX",
        "San Francisco, CA",
        "New York, NY",
        "Chicago, IL",
        "Houston, TX",
        "Phoenix, AZ",
        "Philadelphia, PA",
        "San Antonio, TX",
        "San Diego, CA",
        "Dallas, TX",
        "San Jose, CA",
        "Jacksonville, FL",
        "Fort Worth, TX",
        "Columbus, OH",
        "Charlotte, NC",
        "Indianapolis, IN",
        "Seattle, WA",
        "Denver, CO",
        "Washington, DC",
        "Boston, MA",
        "El Paso, TX",
        "Nashville, TN",
        "Detroit, MI",
        "Oklahoma City, OK",
        "Portland, OR",
        "Las Vegas, NV",
        "Memphis, TN",
        "Louisville, KY",
        "Baltimore, MD",
        "Milwaukee, WI",
        "Albuquerque, NM",
        "Tucson, AZ",
        "Fresno, CA",
        "Sacramento, CA",
        "Mesa, AZ",
        "Kansas City, MO",
        "Atlanta, GA",
        "Omaha, NE",
        "Raleigh, NC",
        "Miami, FL",
        "Long Beach, CA",
        "Virginia Beach, VA",
        "Oakland, CA",
        "Minneapolis, MN",
        "Tulsa, OK",
        "Arlington, TX",
        "New Orleans, LA",
        "Wichita, KS",
        "Cleveland, OH",
        "Tampa, FL",
        "Bakersfield, CA",
        "Aurora, CO",
        "Honolulu, HI",
        "Anaheim, CA",
        "Santa Ana, CA",
        "Corpus Christi, TX",
        "Riverside, CA",
        "St. Louis, MO",
        "Lexington, KY",
        "Stockton, CA",
        "Pittsburgh, PA",
        "Anchorage, AK",
        "Cincinnati, OH",
        "Henderson, NV",
    ];

    const filteredCities = searchTerm
        ? cities.filter((city) =>
              city.toLowerCase().includes(searchTerm.toLowerCase())
          )
        : cities;

    const handleClickOutside = (event) => {
        if (
            dropdownRef.current &&
            !dropdownRef.current.contains(event.target)
        ) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <>
            <button
                className="city-picker-button"
                onClick={() => setIsOpen(!isOpen)}
            >
                {selectedCity}
            </button>
            {isOpen && (
                <div className="city-picker-dropdown" ref={dropdownRef}>
                    <input
                        type="text"
                        placeholder="Search for a city..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <ul>
                        {filteredCities.map((city) => (
                            <li
                                key={city}
                                onClick={() => {
                                    onCitySelect(city);
                                    setIsOpen(false);
                                }}
                            >
                                {city}
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </>
    );
}

export default CityPicker;
