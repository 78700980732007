import React from "react";
import { useNavigate } from "react-router-dom";
import "./CreateEventButton.css";

const CreateEventButton = () => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate("/create-event");
    };

    return (
        <button className="create-event-button" onClick={handleClick}>
            ✏️ create event
        </button>
    );
};

export default CreateEventButton;
