import React, { useEffect, useState, useRef } from "react";
import { database, auth, storage } from "../firebaseConfig";
import {
    doc,
    onSnapshot,
    collection,
    getDocs,
    getDoc,
    query,
    where,
    deleteDoc,
    updateDoc,
} from "firebase/firestore";
import { ref, deleteObject } from "firebase/storage";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { GoogleMap, MarkerF } from "@react-google-maps/api";
import "./EventLandingPage.css";
import Confetti from "react-confetti";
import EventOptionsModal from "../components/EventOptionsModal/EventOptionsModal";
import LoginModal from "../components/LoginModal/LoginModal";

const EventLandingPage = () => {
    const { eventId } = useParams();
    const [eventData, setEventData] = useState(null);
    const [hostData, setHostData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [attendingUsers, setAttendingUsers] = useState([]);
    const [isOptionsModalOpen, setIsOptionsModalOpen] = useState(false);
    const navigate = useNavigate();
    const [showConfetti, setShowConfetti] = useState(false);
    const [confettiConfig, setConfettiConfig] = useState({
        recycle: true,
        numberOfPieces: 700,
        width: window.innerWidth,
        height: window.innerHeight,
    });
    const [isAttending, setIsAttending] = useState(false);
    const [isHost, setIsHost] = useState(false);
    const [showLoginModal, setShowLoginModal] = useState(false);

    const [showFullDescription, setShowFullDescription] = useState(false);

    const toggleDescription = () => {
        setShowFullDescription(!showFullDescription);
    };

    const loginModalRef = useRef(null);

    useEffect(() => {
        const fetchEventData = async () => {
            try {
                const eventDoc = doc(database, `events/${eventId}`);
                onSnapshot(eventDoc, (docSnapshot) => {
                    const data = docSnapshot.data();
                    if (data) {
                        setEventData(data);
                        setIsLoading(false);
                    } else {
                        console.error("Error fetching event data");
                        setIsLoading(false);
                    }
                });
            } catch (error) {
                console.error("Error fetching event data:", error);
                setIsLoading(false);
            }
        };

        if (eventId) {
            fetchEventData();
        }
    }, [eventId]);

    useEffect(() => {
        const fetchHostData = async () => {
            try {
                if (eventData && eventData.uid) {
                    const hostDoc = doc(database, `users/${eventData.uid}`);
                    onSnapshot(hostDoc, (docSnapshot) => {
                        const data = docSnapshot.data();
                        if (data) {
                            setHostData(data);
                        }
                    });
                }
            } catch (error) {
                console.error("Error fetching host data:", error);
            }
        };

        fetchHostData();
    }, [eventData]);

    useEffect(() => {
        const fetchAttendingUsers = async () => {
            try {
                if (eventData && eventData.attending && eventData.uid) {
                    const usersRef = collection(database, "users");
                    const filteredAttendingUserIds = eventData.attending.filter(
                        (uid) => uid !== eventData.uid
                    );

                    if (filteredAttendingUserIds.length > 0) {
                        const usersQuery = query(
                            usersRef,
                            where(
                                "uid",
                                "in",
                                filteredAttendingUserIds.slice(0, 10)
                            )
                        );
                        const querySnapshot = await getDocs(usersQuery);
                        const profiles = querySnapshot.docs.map((doc) => ({
                            id: doc.id,
                            ...doc.data(),
                        }));
                        setAttendingUsers(profiles);
                    } else {
                        setAttendingUsers([]);
                    }
                }
            } catch (error) {
                console.error("Error fetching attending users:", error);
            }
        };

        fetchAttendingUsers();
    }, [eventData]);

    useEffect(() => {
        const checkAttendance = () => {
            if (
                eventData &&
                eventData.attending &&
                auth.currentUser &&
                eventData.attending.includes(auth.currentUser.uid)
            ) {
                setIsAttending(true);
            } else {
                setIsAttending(false);
            }
        };

        const checkHostStatus = () => {
            if (
                eventData &&
                auth.currentUser &&
                eventData.uid === auth.currentUser.uid
            ) {
                setIsHost(true);
            } else {
                setIsHost(false);
            }
        };

        checkAttendance();
        checkHostStatus();
    }, [eventData]);

    const handleRSVP = async () => {
        if (!auth.currentUser) {
            setShowLoginModal(true);
            return;
        }

        try {
            const eventRef = doc(database, "events", eventId);
            const updatedAttendingUserIds = eventData.attending
                ? [...eventData.attending, auth.currentUser.uid]
                : [auth.currentUser.uid];
            await updateDoc(eventRef, { attending: updatedAttendingUserIds });
            setIsAttending(true);
            setShowConfetti(true); // Trigger the confetti effect
        } catch (error) {
            console.error("Error updating event attendance:", error);
        }
    };

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const confettiToken = searchParams.get("confettiToken");
        if (confettiToken) {
            setShowConfetti(true);
            // Remove the confettiToken from the URL
            window.history.replaceState(null, "", `/event/${eventId}`);
        }
    }, [location.search, eventId]);

    useEffect(() => {
        const handleResize = () => {
            setConfettiConfig((prevConfig) => ({
                ...prevConfig,
                width: window.innerWidth,
                height: window.innerHeight,
            }));
        };

        window.addEventListener("resize", handleResize);

        if (showConfetti) {
            // Start the confetti effect with recycling enabled
            setConfettiConfig((prevConfig) => ({
                ...prevConfig,
                recycle: true,
                numberOfPieces: 200,
                gravity: 0.03, // Update the gravity value here
                drag: 1, // Update the drag value here
            }));

            // Stop recycling confetti after 3.5 seconds
            const recycleTimer = setTimeout(() => {
                setConfettiConfig((prevConfig) => ({
                    ...prevConfig,
                    recycle: false,
                }));
            }, 3500);

            // Stop the confetti effect completely after 8 seconds
            const stopTimer = setTimeout(() => {
                setShowConfetti(false);
            }, 8000);

            return () => {
                clearTimeout(recycleTimer);
                clearTimeout(stopTimer);
                window.removeEventListener("resize", handleResize);
            };
        }

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [showConfetti]);

    const mapContainerStyle = {
        width: "100%",
        height: "400px",
        borderRadius: "10px",
        boxShadow: "0 2px 6px rgba(0, 0, 0, 0.3)",
    };

    const defaultCenter = {
        lat: 0,
        lng: 0,
    };

    const [mapCenter, setMapCenter] = useState(defaultCenter);

    const handleDeleteEvent = async () => {
        try {
            const eventRef = doc(database, "events", eventId);

            // Get the event data
            const eventSnapshot = await getDoc(eventRef);
            const eventData = eventSnapshot.data();

            // Delete the event photo from storage if it exists
            if (eventData.eventImage) {
                const photoRef = ref(storage, eventData.eventImage);
                await deleteObject(photoRef);
                console.log("Event photo deleted successfully");
            }

            // Delete the event document from Firestore
            await deleteDoc(eventRef);
            console.log("Event deleted successfully");
            navigate("/profile"); // Navigate to the events page after deletion
        } catch (error) {
            console.error("Error deleting event:", error);
        }
    };

    const handleUnattendEvent = async () => {
        try {
            const eventRef = doc(database, "events", eventId);
            const updatedAttendingUserIds = eventData.attending.filter(
                (uid) => uid !== auth.currentUser.uid
            );
            await updateDoc(eventRef, {
                attending: updatedAttendingUserIds,
            });
            setIsAttending(false);
        } catch (error) {
            console.error("Error updating event attendance:", error);
        }
    };

    const handleCopyEvent = () => {
        const eventUrl = window.location.href;
        navigator.clipboard.writeText(eventUrl).then(
            () => {
                const copyButton = document.querySelector(".copy-event-button");
                copyButton.classList.add("clicked");
                setTimeout(() => {
                    copyButton.classList.remove("clicked");
                }, 2000);
            },
            (err) => {
                console.error("Failed to copy event URL: ", err);
            }
        );
    };

    useEffect(() => {
        const fetchMapCenter = async () => {
            if (eventData && eventData.eventAddress) {
                const response = await fetch(
                    `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
                        eventData.eventAddress
                    )}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`
                );
                const data = await response.json();
                if (data.results.length > 0) {
                    const { lat, lng } = data.results[0].geometry.location;
                    setMapCenter({ lat, lng });
                }
            }
        };

        fetchMapCenter();
    }, [eventData]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                loginModalRef.current &&
                !loginModalRef.current.contains(event.target)
            ) {
                setShowLoginModal(false);
            }
        };

        if (showLoginModal) {
            document.addEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [showLoginModal]);

    if (isLoading) {
        return <div className="loading-wheel"></div>;
    }

    const handleLoginSuccess = async () => {
        try {
            const eventRef = doc(database, "events", eventId);
            const eventSnap = await getDoc(eventRef);
            const eventData = eventSnap.data();

            if (
                eventData &&
                eventData.attending &&
                !eventData.attending.includes(auth.currentUser.uid)
            ) {
                const updatedAttendingUserIds = [
                    ...eventData.attending,
                    auth.currentUser.uid,
                ];
                await updateDoc(eventRef, {
                    attending: updatedAttendingUserIds,
                });
                setIsAttending(true);
            }

            setShowLoginModal(false);
        } catch (error) {
            console.error("Error updating event attendance:", error);
        }
    };

    const {
        eventTitle: title,
        eventDescription: description,
        eventLink: pageUrl,
        eventImage: imageUrl,
        eventDate,
        eventEndDate,
        eventAddress: eventLocation,
    } = eventData || {};

    const formatDate = (date) => {
        return date
            ? new Intl.DateTimeFormat("en-US", {
                  month: "long",
                  day: "numeric",
                  year: "numeric",
                  hour: "numeric",
                  minute: "2-digit",
                  hour12: true,
              }).format(date.toDate())
            : "";
    };

    const handleOptionsClick = () => {
        setIsOptionsModalOpen(true);
    };

    const handleOptionsModalClose = () => {
        setIsOptionsModalOpen(false);
    };

    const handleEditEvent = () => {
        navigate(`/edit-event/${eventId}`, {
            state: { eventData: { ...eventData, id: eventId } },
        });
    };

    const readableEventDate = formatDate(eventDate);
    const readableEventEndDate = formatDate(eventEndDate);
    const formattedEventDate = eventDate
        ? eventDate.toDate().toISOString()
        : "";
    const formattedEventEndDate = eventEndDate
        ? eventEndDate.toDate().toISOString()
        : "";

    return (
        <div className="event-landing-page">
            <div className="confetti-container">
                {showConfetti && <Confetti {...confettiConfig} />}
            </div>
            <div className="event-landing-header">
                <div className="event-header-content">
                    <div className="event-host-info">
                        <div className="event-host">
                            {hostData && hostData.profileImageUrl && (
                                <img
                                    src={hostData.profileImageUrl}
                                    alt="Host"
                                    className="host-image"
                                />
                            )}
                            <span className="host-name">
                                {hostData ? hostData.name : "Host"}
                                {isHost && "  👑"}
                            </span>
                        </div>
                        <button
                            className="copy-event-button"
                            onClick={handleCopyEvent}
                        >
                            <span className="original">Copy Event 🔗</span>
                            <span className="copied">Copied!</span>
                        </button>
                        <button
                            className="event-options-button"
                            onClick={handleOptionsClick}
                        >
                            <span
                                role="img"
                                aria-label="Options"
                                className="emoji"
                            >
                                ℹ️
                            </span>
                        </button>
                        <EventOptionsModal
                            isOpen={isOptionsModalOpen}
                            onClose={handleOptionsModalClose}
                            isHost={
                                eventData &&
                                auth.currentUser &&
                                eventData.uid === auth.currentUser.uid
                            }
                            isAttending={isAttending}
                            onEditEvent={handleEditEvent}
                            onDeleteEvent={handleDeleteEvent}
                            onUnattendEvent={handleUnattendEvent}
                        />
                    </div>
                    <h1 className="event-title">{title}</h1>
                    <p className="event-location-category">
                        {eventData &&
                        eventData.eventLocation &&
                        eventData.eventCategory ? (
                            <span>
                                {eventData.eventLocation} -{" "}
                                {eventData.eventCategory}
                            </span>
                        ) : (
                            <>
                                {eventData && eventData.eventLocation && (
                                    <span>{eventData.eventLocation}</span>
                                )}
                                {eventData && eventData.eventCategory && (
                                    <span>{eventData.eventCategory}</span>
                                )}
                            </>
                        )}
                    </p>
                    <p className="event-date">
                        {readableEventDate}
                        {readableEventEndDate && ` - ${readableEventEndDate}`}
                    </p>
                </div>
                {imageUrl && (
                    <img
                        src={imageUrl}
                        alt="Event"
                        className="event-landing-image"
                    />
                )}
            </div>
            <div className="event-details">
                {attendingUsers.length > 0 && (
                    <div className="event-landing-attending-users">
                        {attendingUsers.map((user, index) => (
                            <img
                                key={user.uid}
                                src={
                                    user.profileImageUrl ||
                                    "/path/to/default-profile-image.png"
                                }
                                alt={`User ${user.name}`}
                                className="event-landing-attending-user-image"
                            />
                        ))}
                    </div>
                )}
                {isHost ? (
                    <></>
                ) : (
                    <>
                        {!isAttending && (
                            <button
                                className="event-rsvp-button"
                                onClick={handleRSVP}
                            >
                                RSVP <span className="emoji">😎</span>
                            </button>
                        )}
                        {isAttending && (
                            <button className="event-attending-button" disabled>
                                Attending <span className="emoji">🥳</span>
                            </button>
                        )}
                    </>
                )}
                {description && (
                    <div className="event-description-landingPage">
                        <p>
                            {showFullDescription || description.length <= 300
                                ? description
                                : `${description.slice(0, 300)}...`}
                        </p>
                        {description.length > 300 && (
                            <button
                                className="show-more-button"
                                onClick={toggleDescription}
                            >
                                {showFullDescription
                                    ? "Show Less"
                                    : "Show More"}
                            </button>
                        )}
                    </div>
                )}
                {eventLocation && (
                    <div className="event-location">
                        <p>
                            <span className="emoji">📍</span>
                            {eventLocation}
                        </p>
                        <div style={{ padding: "10px" }}>
                            <GoogleMap
                                mapContainerStyle={mapContainerStyle}
                                center={mapCenter}
                                zoom={14}
                            >
                                <MarkerF position={mapCenter} />
                            </GoogleMap>
                        </div>
                    </div>
                )}
                {pageUrl && (
                    <div className="event-link">
                        <a
                            href={pageUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <span className="emoji">🔗</span> event website
                        </a>
                    </div>
                )}
                {attendingUsers.length > 0 && (
                    <div className="attending-users-list">
                        <h3>
                            <span className="emoji">💫</span> Attending{" "}
                            <span className="attending-users-count">
                                ({attendingUsers.length})
                            </span>
                        </h3>
                        {attendingUsers.map((user) => (
                            <div key={user.uid} className="attending-user">
                                <img
                                    src={
                                        user.profileImageUrl ||
                                        "/path/to/default-profile-image.png"
                                    }
                                    alt={`${user.name}'s profile`}
                                    className="attending-user-image"
                                />
                                <span className="attending-user-name">
                                    {user.name}
                                </span>
                            </div>
                        ))}
                    </div>
                )}
            </div>
            {showLoginModal && (
                <div className="login-modal-overlay">
                    <div className="login-modal-content" ref={loginModalRef}>
                        <LoginModal
                            onLoginSuccess={handleLoginSuccess}
                            eventId={eventId}
                            isRSVP={true}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default EventLandingPage;
