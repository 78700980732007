import { Component } from "react";

class AppleAppSiteAssociation extends Component {
    render() {
        const appleAppSiteAssociation = {
            applinks: {
                apps: [],
                details: [
                    {
                        appID: "WD9GCMH3HS.day.socal.socal",
                        paths: ["/event/*"],
                    },
                ],
            },
        };

        return <pre>{JSON.stringify(appleAppSiteAssociation, null, 2)}</pre>;
    }
}

export default AppleAppSiteAssociation;
