import React from "react";
import { LoadScriptNext } from "@react-google-maps/api";

const libraries = ["places"];

const GoogleMapsWrapper = ({ children }) => {
    return (
        <LoadScriptNext
            googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
            libraries={libraries}
            loadingElement={<div>Loading...</div>}
        >
            {children}
        </LoadScriptNext>
    );
};

export default GoogleMapsWrapper;
