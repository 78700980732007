import React, { useState, useEffect } from "react";
import ReactDatePicker from "react-datepicker";
import { useNavigate } from "react-router-dom";
import "./EventExplorePage.css";
import DateDial from "../components/DateDial/DateDial";
import CityPicker from "../components/CityPicker/CityPicker";
import "../components/CityPicker/CityPicker.css";
import EventCard from "../components/EventCard/EventCard";
import {
    collection,
    query,
    where,
    orderBy,
    onSnapshot,
} from "firebase/firestore";
import { database } from "../firebaseConfig";
import LoadingWheel from "../components/LoadingWheel/LoadingWheel";
import CreateEventButton from "../components/CreateEventButton/CreateEventButton";

export function formatDate(timestamp) {
    return new Intl.DateTimeFormat("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: true,
    }).format(new Date(timestamp));
}

function generateDates(start, end) {
    const dates = [];
    let currentDate = new Date(start);
    while (currentDate <= end) {
        dates.push(new Date(currentDate));
        currentDate.setDate(currentDate.getDate() + 1);
    }
    return dates;
}

function EventExplorePage() {
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [weekDates, setWeekDates] = useState([]);
    const [isCalendarVisible, setIsCalendarVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [eventsByDate, setEventsByDate] = useState({});
    const [selectedCity, setSelectedCity] = useState("Austin, TX");

    const toggleCalendarVisibility = () => {
        setIsCalendarVisible((prev) => !prev);
        document.body.classList.toggle("calendar-visible", !isCalendarVisible);
    };

    const handleDateSelect = (date) => {
        setSelectedDate(date);
        setIsCalendarVisible(false);
    };

    const handleCloseCalendar = () => setIsCalendarVisible(false);

    useEffect(() => {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        const endRange = new Date(today);
        endRange.setDate(today.getDate() + 364);
        const initialDates = generateDates(today, endRange);
        setWeekDates(initialDates);
    }, []);

    useEffect(() => {
        setIsLoading(true);
        const unsubscribe = subscribeToEvents(selectedCity, selectedDate);

        return () => {
            unsubscribe();
        };
    }, [selectedCity, selectedDate]);

    const subscribeToEvents = (selectedCity, selectedDate) => {
        const startOfDay = new Date(selectedDate);
        startOfDay.setHours(0, 0, 0, 0);
        const endOfDay = new Date(selectedDate);
        endOfDay.setHours(23, 59, 59, 999);

        const eventsRef = collection(database, "events");
        const q = query(
            eventsRef,
            where("eventLocation", "==", selectedCity),
            where("eventDate", ">=", startOfDay),
            where("eventDate", "<=", endOfDay),
            orderBy("eventDate", "asc")
        );

        return onSnapshot(q, (snapshot) => {
            const fetchedEvents = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));

            setEventsByDate((prevEventsByDate) => ({
                ...prevEventsByDate,
                [selectedDate.toDateString()]: fetchedEvents,
            }));

            setIsLoading(false);
        });
    };

    return (
        <div className="event-explore-page">
            <div className="selectors-container">
                <button
                    className="selected-date-display"
                    onClick={toggleCalendarVisibility}
                >
                    {selectedDate.toLocaleDateString("en-US", {
                        month: "long",
                        year: "numeric",
                    })}
                </button>
                <CityPicker
                    selectedCity={selectedCity}
                    onCitySelect={(city) => {
                        setSelectedCity(city);
                    }}
                />
            </div>

            {isCalendarVisible && (
                <div
                    className="calendar-backdrop"
                    onClick={handleCloseCalendar}
                >
                    <div
                        className="datepicker-container"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <ReactDatePicker
                            selected={selectedDate}
                            onChange={handleDateSelect}
                            minDate={new Date()}
                            inline
                        />
                    </div>
                </div>
            )}
            <CreateEventButton />

            {weekDates.length > 0 && (
                <DateDial
                    weekDates={weekDates}
                    selectedDate={selectedDate}
                    onDateSelect={handleDateSelect}
                    selectedCity={selectedCity}
                />
            )}
            {isLoading ? (
                <LoadingWheel />
            ) : eventsByDate[selectedDate.toDateString()]?.length > 0 ? (
                <ul>
                    {eventsByDate[selectedDate.toDateString()].map(
                        (event, index) => (
                            <EventCard
                                key={`${event.id}-${index}`}
                                item={event}
                                attendingUserIds={event.attending || []}
                            />
                        )
                    )}
                </ul>
            ) : (
                <p>no events this day 🥺</p>
            )}
        </div>
    );
}

export default EventExplorePage;
